import { forwardRef, useEffect, useMemo, useState } from "react";
import { Slide, Dialog, DialogContent, IconButton, Box, useMediaQuery, Grid, TextField, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { endpoint } from "../../config";
import axios from "axios"
import { customToast } from "../../lib/toastLib";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function CreditCardDialog({
	open,
	name,
	record,
	onClose,
	onSave,
	module,
	type = "text"
}) {
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
	const [value, setValue] = useState("");
	const [marketing, setMarketing] = useState([""]);

	useEffect(() => {
		if (name === 'marketing_bullets') {
			setMarketing(record[name])
		}
		if (!['category_img_thumb', 'marketing_bullets'].includes(name)) {
			setValue(record[name])
		}
	}, [name])

	const handleAttachmentChange = async (e) => {
		try {
			const formData = new FormData();
			formData.append("image_name", e.target.files[0]);
			formData.append("path", `${record.category_name} - logo`);

			const { data } = await axios.post(`${endpoint}/api/s3-upload-file`, formData);
			setValue(data.file);
		} catch (error) {
			console.error("Error::: ", error)
			customToast.error(error)
		}
	}

	const handleMarketingChange = (e, index) => {
		setMarketing((prev) => {
			return prev.map((i, idx) => {
				if (idx === index) return e.target.value
				return i;
			})
		})
	}

	const handleFieldChange = (e) => {
		const { value } = e.target;

		if (name === 'sort_order' || type === 'number') {
			if (isNaN(Number(value))) return;
		}

		if (name === "utm_id") {
			if (!(value.match(/^[A-Za-z0-9_-]+$/))) return;
		}

		setValue(value);
	}

	const submitBtnDisabled = useMemo(() => {
		return name === 'marketing_bullets' ? marketing.some((i) => !i) : !value
	}, [value, marketing, name])

	const handleSubmit = async (e) => {
		try {
			e.preventDefault();
			setSubmitBtnLoading(true)

			if (module === 'partner' || module === 'lenders') {
				const payload = {
					revenue_percent: (name === "revenue_percent") ? value : record.revenue_percent,
					revenue_flat_fee: (name === "revenue_flat_fee") ? value : record.revenue_flat_fee
				}

				if (module === 'partner' && name === "utm_id") {
					await axios.post(`${endpoint}/api/update-partner-utm/${record.id}`, {
						utm_id: value
					})
				}

				if (module === 'partner' && name !== "utm_id") {
					await axios.post(`${endpoint}/api/update-partner-revenue-data/${record.id}`, payload)
				}

				if (module === 'lenders') {
					await axios.post(`${endpoint}/api/update-lender-data`, {
						lender_id: record.id,
						property: name,
						value: value
					})
				}
			} else {
				const payload = {
					category_id: record.category_id,
					property: name,
					value: (name === 'marketing_bullets') ? marketing : value
				}

				await axios.post(`${endpoint}/api/update-credit-card-data`, payload)
			}

			setValue("")
			setMarketing([""])
			onSave();
		} catch ({ response }) {
			console.error("Error::: ", response)
			customToast.error(response.data.message)
		} finally {
			setSubmitBtnLoading(false)
		}
	}

	return (
		<Dialog
			open={open}
			scroll="paper"
			fullScreen={fullScreen}
			fullWidth
			TransitionComponent={Transition}
			component="form"
			onSubmit={handleSubmit}
			autoComplete="off"
		>
			<DialogContent>
				<Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
					<IconButton onClick={() => onClose(false)}>
						<CloseIcon />
					</IconButton>
					<Grid item xs={12} mb={2}>
						{name === 'category_img_thumb' && <TextField
							type="file"
							margin="dense"
							required
							fullWidth
							label={t("Attach File")}
							name="attachment"
							onChange={handleAttachmentChange}
							InputLabelProps={{ shrink: true }}
						/>}

						{name === 'marketing_bullets' && (
							<>
								{marketing.map((_, idx) => (
									<Box
										key={idx}
										sx={{
											display: 'flex',
											alignItems: 'center',
											mb: 2,
										}}
									>
										<TextField
											required
											fullWidth
											name={name}
											value={marketing[idx]}
											onChange={(e) => handleMarketingChange(e, idx)}
										/>
										<IconButton
											color="primary"
											size="small"
											sx={{
												background: 'white',
												'&:hover': {
													background: 'white'
												}
											}}
											onClick={() => {
												setMarketing((prev) => {
													return prev.filter((_, i) => i !== idx)
												})
											}}
										>
											<DeleteIcon />
										</IconButton>
									</Box>
								))}
								<Box textAlign={"end"}>
									<Button
										variant="contained"
										startIcon={<AddIcon />}
										onClick={() => setMarketing((prev) => {
											const data = [...prev];
											data.push("");
											return data;
										})}
									>
										{t("Add")}
									</Button>
								</Box>
							</>)}

						{!['category_img_thumb', 'marketing_bullets'].includes(name) && <TextField
							required
							fullWidth
							name={name}
							value={value}
							onChange={handleFieldChange}
						/>}
					</Grid>

					<Grid item xs={12}>
						<LoadingButton
							color="secondary"
							variant="contained"
							size="large"
							fullWidth
							type="submit"
							disabled={submitBtnDisabled}
							loading={submitBtnLoading}
						>
							{t("Submit")}
						</LoadingButton>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}
