const envVariables = {
  DEV: {
    endpoint: "https://dev.loannet.in:8000",
    // endpoint: "http://13.232.211.137:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  QA: {
    endpoint: "https://www.loannet.in:8000",
    // endpoint: "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  QA_LENDCONNECT: {
    endpoint: "https://www.lendconnect.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_EDHAMONEY: {
    endpoint: "https://www.edhamoney-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_GSSMART: {
    endpoint: "https://www.gssmart-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_VAKRANGEE: {
    endpoint: "https://www.vakrangee-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_MONEYCHAMP: {
    endpoint: "https://www.moneychamp-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_IWEBTECHNO: {
    endpoint: "https://www.iwebtech-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_TOSHANIPREMIUM: {
    endpoint: "https://www.toshani-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_FAYDAPAY: {
    endpoint: "https://www.reijiro-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_VAKRANGEEGOLD: {
    endpoint: "https://www.vakrangee-gl-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_ASKCREDIT: {
    endpoint: "https://askcredit-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_LIDSERVICES: {
    endpoint: "https://lidservices-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_PAYMONK: {
    endpoint: "https://actas-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_JEEVAN_UTTHAN: {
    endpoint: "https://juindia-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_INFORTS: {
    endpoint: "https://infortsindia-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_1TOUCHPE: {
    endpoint: "https://1touchpe-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_EZEEPAY: {
    endpoint: "https://ezeepay-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_DIRSTI: {
    endpoint: "https://dirsti-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_VISITMYLOAN: {
    endpoint: "https://visitmyloan-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_WINORAA: {
    endpoint: "https://winoraa-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_YGOCOMPANY: {
    endpoint: "https://www.ygocompany-financialservices.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_NITYA_FINANCE: {
    endpoint: "https://www.nityafinance-financialservices.com:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_DAYPFINANCE: {
    endpoint: "https://www.daypfinance-financialservices.com:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_AADARSH_FINANCE: {
    endpoint: "https://www.loginaadarshfinance-financialservices.com:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_REALZONE: {
    endpoint: "https://www.loginrealzonefinance-financialservices.com:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_MONEYFLOW: {
    endpoint: "https://www.moneyflow-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_WEBPE: {
    endpoint: "https://www.webpe-credilinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_VIREN: {
    endpoint: "https://www.vbiz-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_MORAL_E_SUVIDHA: {
    endpoint: "https://www.onlinebcpoint-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_YOKE: {
    endpoint: "https://www.wltpe-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_INDEZON: {
    endpoint: "https://www.indezon-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_DHANVRIDDHIASSURANCE: {
    endpoint: "https://dhanvriddhiassurance.com:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD_FIVEKCR: {
    endpoint: "https://fivekcr-creditlinks.in:8000",
    // "endpoint": "http://3.108.211.60:8000",
    needHelpApi:
      "https://d6c0000008alpuaa--devenv.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D7h000000H29o",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: false,
  },
  PROD: {
    endpoint: "https://l.creditlinks.in:8000",
    needHelpApi:
      "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D41000001PNo6",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: true,
  },
  PROD_SARALPE: {
    endpoint: "https://l.creditlinks.in:8000",
    needHelpApi:
      "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    needHelpOrgId: "00D41000001PNo6",
    s3BucketName: "creditlinks-lap.s3.ap-south-1",
    enableGoogleAnalytics: true,
  },
  LOCAL: {
    // endpoint: 'http://127.0.0.1',
    endpoint: "https://lap-core-back-end.test",
  },
};

export const app_title = process.env.REACT_APP_NAME || "";
export const corporate_name = "Asset Direct DBA CreditLinks India Pvt. Ltd.";

export const endpoint =
  process.env.REACT_APP_API_ENDPOINT ||
  envVariables[process.env.REACT_APP_ENV]?.endpoint ||
  envVariables.DEV.endpoint;

export const urlSegmentRegex = /[a-zA-Z0-9]{2,}/;
export const defaultUrlSegment = "TOPCLIND";
export const uuidRegex =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
export const emailPattern = /^\w+([._%+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const panPattern = /^[A-Z]{3}[P][*A-Z][*0-9]{4}[*A-Z]$/i;
export const businessPanPattern = /^[A-Z]{4}[*A-Z][*0-9]{4}[*A-Z]$/i;
export const webPattern = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
export const panRegex = /^[a-zA-Z0-9]{0,10}$/;
export const nameRegx = /^[A-Za-z]+$/;
export const adrRegex = /^[a-zA-Z0-9-,.# ]*$/;
export const userNameRegx =
  /^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$/;

export const num_length = 10;
export const otp_length = 6;
export const pincode_length = 6;
export const longTextLimit = 150;
export const min_monthly_salary = 1;
export const max_monthly_salary = 1000000;
export const maxAge = 120;
export const min_monthly_income_for_household = 25000;
export const idle_activity_minutes = 10;
export const admin_idle_activity_minutes = 180;
export const idle_prompt_seconds = 15;

export const help_mailAddress = "contact@creditlinks.in";
export const phoneCode = 91;
export const currencyVal = "INR";
export const currencySymbol = "₹";
export const resendOTPTime = 74;
export const locale = "en-IN";
export const countryCode = "IN";

export const upwards_bnkaccnum_length = 15;
export const upwards_ifsc_length = 15;
export const upwards_ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

export const moneyTap_ifsc_length = 15;
export const moneyTap_ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
export const moneyTap_officeEmail_length = 70;
export const moneyTap_companyNameRegex = /^[A-Za-z0-9 ]{3,40}$/;

export const cashe_localityRegex = /^[a-zA-Z0-9-,.# ]*$/;

export const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const needHelpApi =
  envVariables[process.env.REACT_APP_ENV]?.needHelpApi ||
  envVariables.DEV.needHelpApi;
export const needHelpOrgId =
  envVariables[process.env.REACT_APP_ENV]?.needHelpOrgId ||
  envVariables.DEV.needHelpOrgId;

const s3BucketName =
  envVariables[process.env.REACT_APP_ENV]?.s3BucketName ||
  envVariables.DEV.s3BucketName;
export const s3StaticImagePath = `https://${s3BucketName}.amazonaws.com/static`;

export const enableGoogleAnalytics =
  envVariables[process.env.REACT_APP_ENV]?.enableGoogleAnalytics ||
  envVariables.DEV.enableGoogleAnalytics;
export const googleAnalyticsUA = "UA-78326623-6";

export const iframeURL = "https://l.creditlinks.in/";

export const ipAddressURL = "https://api.ipify.org/?format=json";

// Pixel Tracking
export const ptDomainTracking = "https://tracking.creditlinks.in";

export const ptNoProdCatID = 0;
export const ptPLCatID = 1;
export const ptINSCatID = 2;
export const ptGLCatID = 4;
export const blCatID = 4;

export const ptVerificationPageID = 1;
export const ptProductsPageID = 2;
export const ptWelcomeBackPageID = 3;
export const ptOffersPageID = 4;
export const ptUserDetailsPageID = 5;
export const ptLendersOffersPageID = 6;

export const blOTPVerifyID = 1;
export const blIntakeFormID = 2;

export const ptInsTransactionPageID = 7;
export const rupiCardURL = "https://play.google.com/store/apps/details?id=com.rupicard&referrer=utm_source%3Dpartner%26utm_medium%3Dclink%26utm_campaign%3Dcl";

export const welcomeCreditLinksVideoLink = '/welcome-to-creditlinks.mp4'