import React, { useState /* , useEffect */ } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Container,
  Grid,
  Button,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { app_title, s3StaticImagePath } from "../config";
import { getJWTTokenFromCookie } from "../utils/index";
import logoutApi from "../hooks/POST/logout";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
/* import { getUrlSegmentFromCookie } from "../utils";
import { defaultUrlSegment } from "../config"; */

export default function GenericHeader() {
  const { t } = useTranslation();
  const authToken = getJWTTokenFromCookie();
  const logoURL = useSelector((state) => state.appInfo.logo);
  // whether to show powered by or not start
  const [showPoweredBy /* , setShowPoweredBy */] = useState(false);

  /* useEffect(() => {
    const intervalFunction = setInterval(() => {
      setShowPoweredBy(getUrlSegmentFromCookie() !== defaultUrlSegment);
    }, 1000);
    return () => clearInterval(intervalFunction);
  }, []); */
  // whether to show powered by or not end

  // handle logout
  const handleLogout = () => {
    logoutApi();
  };

  return (
    <AppBar position="sticky" color="inherit" style={{ overflow: "auto" }}>
      <Container maxWidth="xl" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Toolbar disableGutters>
          {/* Desktop part start */}
          <Box sx={{ mr: 2, display: { xs: "none", md: "flex" } }}>
            {logoURL && <img
              src={logoURL}
              alt={app_title}
              style={{ maxHeight: 75, maxWidth: 202, padding: 5, marginRight: 10 }}
            />}
            {showPoweredBy && (
              <Grid container>
                <Grid container alignItems="center">
                  <Typography variant="caption">{t("Powered by")}</Typography>
                  <img
                    src={`${s3StaticImagePath}/ongo_logo.png`}
                    alt="powered by"
                    style={{ height: 15, marginLeft: 5 }}
                  />
                </Grid>
                <Grid container>
                  <Typography fontStyle="italic" variant="caption">
                    Dynamic Caption
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }} />
          {/* Desktop part end */}

          {/* Mobile part start */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {logoURL && <img
              src={logoURL}
              alt={app_title}
              style={{ maxHeight: 75, maxWidth: 202, padding: 5, marginRight: 10 }}
            />}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            {showPoweredBy && (
              <Grid container>
                <Grid container alignItems="center">
                  <Typography variant="caption">{t("Powered by")}</Typography>
                  <img
                    src={`${s3StaticImagePath}/ongo_logo.png`}
                    alt="powered by"
                    style={{ height: 15, marginLeft: 5 }}
                  />
                </Grid>
                <Grid container>
                  <Typography fontStyle="italic" variant="caption">
                    Dynamic Caption
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>

          {/* Mobile part end */}
        </Toolbar>
        {authToken && (<Button
          startIcon={<LogoutIcon />}
          color="error"
          sx={{ alignItems: "start" }}
          onClick={handleLogout}
        >
          {t("Logout")}
        </Button>)}

      </Container>
    </AppBar>
  );
}
