import { useTheme } from '@emotion/react';
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function MerchantRecords({ open, handleClose, title, records, showHeading }) {
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const ids = records.records || [];
	const heading = showHeading !== false;

	return <Dialog
		open={open}
		scroll="paper"
		fullScreen={fullScreen}
		fullWidth
		TransitionComponent={Transition}
		autoComplete="off"
	>
		<DialogTitle>
			<Grid container justifyContent="space-between" alignItems="center">
				<Typography variant="h6">{title}</Typography>
				<IconButton onClick={() => handleClose(false)}>
					<CloseIcon />
				</IconButton>
			</Grid>
		</DialogTitle>
		<Divider />
		<DialogContent>
			<Grid container>
				<TableContainer component={Paper} variant="outlined">
					<Table>
						<TableBody>
							{records.merchantsCreatedRows !== false &&
								<TableRow>
									<TableCell>Created</TableCell>
									<TableCell sx={{ fontWeight: 'bold' }}>{records.merchantsCreatedRows}</TableCell>
								</TableRow>}
							{records.merchantsUpdatedRows !== false &&
								<TableRow>
									<TableCell>Updated</TableCell>
									<TableCell sx={{ fontWeight: 'bold' }}>{records.merchantsUpdatedRows}</TableCell>
								</TableRow>}
						</TableBody>
					</Table>
				</TableContainer>
				<Typography my={2} variant="p" component="h4">{records.tableTitle}</Typography>
				<TableContainer component={Paper} variant="outlined">
					<Table>
						{heading &&
							<TableHead>
								<TableRow>	
									<TableCell>#</TableCell>
									<TableCell>UUID</TableCell>
								</TableRow>
							</TableHead>}
						<TableBody>
							{ids.map((id, index) => (
								<TableRow key={index}>
									<TableCell>#{index + 1}</TableCell>
									<TableCell>{id}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</DialogContent>
	</Dialog>;
}
