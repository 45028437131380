import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

function WhiteLabelVersion() {
  const appInfo = useSelector((state) => state.appInfo);

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link rel="stylesheet" href={appInfo.googleFontsAPI} />
        <style>
          {`
					* {
						font-family: ${appInfo.fontFamily} !important;
					}
					`}
        </style>
      </Helmet>
      <Outlet />
    </>
  );
}

export default WhiteLabelVersion;
