import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { googleAnalyticsUA } from "../config";

export default function GoogleAnalytics() {
  const { pathname } = useLocation();

  // initializig GA only on page load
  useEffect(() => {
    ReactGA.initialize(googleAnalyticsUA);
  }, []);

  // pageview method hit whenever path changes
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [pathname]);

  return null;
}
