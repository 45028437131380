import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ErrorIcon from "@mui/icons-material/Error";
import {
  clearAdminCookieData,
  clearCookieData,
  getAdminJWTTokenFromCookie,
  getJWTTokenFromCookie,
  sleep,
} from "./../utils";
import { admin_idle_activity_minutes, idle_activity_minutes, idle_prompt_seconds } from "../config";

function UserIdleTimer() {
  const [isIdleTimePrompOpen, setIsIdleTimePrompOpen] = useState(false);
  const [second, setSecond] = useState(idle_prompt_seconds);
  const url = window.location.pathname;

  useEffect(() => {
    async function counter() {
      if (isIdleTimePrompOpen) {
        for (let i = idle_prompt_seconds - 1; i >= 0; i--) {
          await sleep(1000);
          setSecond(i);
        }
        clearCookieData();
        clearAdminCookieData();
        window.location.reload();
      }
    }
    counter();
  }, [isIdleTimePrompOpen]);

  // user idle timout set start
  const onPrompt = () => {
    if (getAdminJWTTokenFromCookie() || getJWTTokenFromCookie()) {
      setIsIdleTimePrompOpen(true);
    }
  };
  // user idle timout set end

  const stayUserLoggedIn = () => {
    window.location.reload();
  }

  useIdleTimer({
    onPrompt,
    timeout: (!url.startsWith('/admin') && !url.startsWith('/partner')) ? 1000 * 60 * idle_activity_minutes : 1000 * 60 * admin_idle_activity_minutes,
    promptTimeout: 1000 * idle_prompt_seconds,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 450,
    width: "calc(100% - 16px)",
    bgcolor: "background.paper",
    outline: 0,
    textAlign: "center",
    borderRadius: 1,
    padding: 4,
  };

  return (
    <Modal
      open={isIdleTimePrompOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography>
          <Box id="modal-modal-description">
            <ErrorIcon
              sx={{ width: "40px", height: "40px", color: "#f54545" }}
            />
            <Typography sx={{ mt: 2 }}>
              Our system has detected more than <span style={{ fontWeight: 600 }}>{idle_activity_minutes}</span> minutes of <span style={{ fontWeight: 600 }}>inactivity</span>. For
              security reasons, you will be logged out in{" "}
              <span style={{ fontWeight: 600 }}>{second}</span> seconds.
            </Typography>
            <Button sx={{ mt: 2 }} onClick={() => stayUserLoggedIn()} variant="outlined" color="success">Stay Logged In!</Button>
          </Box>
        </Typography>
      </Box>
    </Modal>
  );
}

export default UserIdleTimer;
