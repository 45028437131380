import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getProductTypeFromCookie } from "../../utils";

export default function CreditCardLayout() {
  const productType = getProductTypeFromCookie();

  if (productType !== "Credit Cards") {
    // not the correct product type, redirect to products
    return <Navigate to={`/products`} />;
  }

  return <Outlet />;
}
