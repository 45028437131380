import { Button, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { app_title, endpoint } from "../../config";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { customToast } from "../../lib/toastLib";
import axios from "axios";
import MerchantRecords from "../../components/admin/MerchantRecords";

export default function ApplicationStatusFileUploader() {
    const { t } = useTranslation();
    const [file, setFile] = useState(null);
    const [isSubmitBtnDisbaled, setIsSubmitBtnDisbaled] = useState(true)
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [application, setApplication] = useState({
        open: false,
        records: [],
        merchantsCreatedRows: false,
        merchantsUpdatedRows: 0,
        title: "",
        tableTitle: ""
    })

    useEffect(() => {
        setIsSubmitBtnDisbaled(file === null)
    }, [file])

    const handleFileChange = (e) => {
        if (!e.target.files.length) {
            setFile(null);
            customToast.error("No file selected");
            return;
        }

        const validExts = [".xlsx", ".xls", ".csv"];
        const fileName = e.target.files[0].name;
        const fileExt = fileName.substring(fileName.lastIndexOf("."));

        // invalid file type selected
        if (validExts.indexOf(fileExt) < 0) {
            document.getElementsByName("file")[0].value = "";
            setFile(null)
            customToast.error("Invalid file type");
            return;
        }

        setFile(e.target.files[0])
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitBtnLoading(true)

        const formData = new FormData();
        formData.append("image_name", file);
        axios.post(`${endpoint}/api/upload-application-status-file`, formData).then(({ data }) => {
            if (data.errors.length > 0) {
                setApplication({
                    open: true,
                    records: data.errors,
                    merchantsCreatedRows: false,
                    merchantsUpdatedRows: data.rowsUpdated || 0,
                    title: "Aplication Status",
                    tableTitle: `${data.errors.length} Errors found`
                })
            } else {
                setFile(null);
                setIsSubmitBtnDisbaled(true);
            }
            customToast.success(`${data.rowsUpdated} rows loaded successfully`)
        }).catch((error) => {
            if (error.response) {
                customToast.error(error.response.data?.message);
            } else {
                customToast.error(error.message);
            }
        }).finally(() => {
            setSubmitBtnLoading(false);
        })
    }

    const handleApiAplicationClose = () => {
        setApplication({
            open: false,
            records: [],
            merchantsCreatedRows: false,
            merchantsUpdatedRows: 0,
            title: ""
        })
    }

    return <>
        <Helmet>
            <title>Admin - Application Status File {app_title && `| ${app_title}` }</title>
        </Helmet>
        <Paper variant="outlined" sx={{ padding: 2 }}>
            <Typography
                variant="h6"
                fontWeight="bold"
                color="secondary.main"
                gutterBottom
            >
                {t("Application Status File")}
            </Typography>
            <Divider />
            <Grid
                container
                alignItems="center"
                spacing={3}
                sx={{ marginTop: 1 }}
                component="form"
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">
                        {t("Choose file")}
                    </Typography>
                    <TextField
                        type="file"
                        margin="dense"
                        onChange={handleFileChange}
                        required
                        fullWidth
                        name="file"
                        helperText={`${t(
                            "Accepted file types"
                        )}: .xlsx, .xls, .csv only`}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        type="submit"
                        disabled={isSubmitBtnDisbaled}
                        loading={submitBtnLoading}
                    >
                        {t("Upload")}
                    </LoadingButton>
                </Grid>
            </Grid>

            <MerchantRecords
                title={application.title}
                open={application.open}
                records={application}
                handleClose={handleApiAplicationClose}
                showHeading={false}
            />
        </Paper>
    </>
}