import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";

const getPartnerIDByPartnerName = async (partnerName = 'cl') => {

  try {
    const apiLink = `${endpoint}/api/get-partner-by-name/${partnerName}`;
    const response = await axios.get(apiLink);
    const data = response.data;
    return data;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

export default getPartnerIDByPartnerName;
