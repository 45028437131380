import { createTheme } from "@mui/material/styles";
import partners, { partnersList } from "./../config/partners";

const partner_name = process.env.REACT_APP_PARTNER_API_NAME;
const primary = partnersList.includes(partner_name)
  ? partners[partner_name].colors.primary
  : partners.l.colors.primary;
const secondary = partnersList.includes(partner_name)
  ? partners[partner_name].colors.secondary
  : partners.l.colors.secondary;

// Create a theme instance.
const theme = (mode) =>
  createTheme({
    palette: {
      primary: {
        dark: "#368c99",
        main: primary,
        light: "#71d3e2",
        contrastText: "#fff",
      },
      secondary: {
        dark: "#040038",
        main: secondary,
        light: "#383373",
      },
      mode: mode ? "dark" : "light",
    },
    shape: {
      borderRadius: 10,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          sizeLarge: { height: 50 },
        },
        defaultProps: {
          disableElevation: true,
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          sizeLarge: { height: 56 },
        },
      },
    },
  });

export default theme;
