import { forwardRef, useMemo, useState } from "react";
import { Slide, Dialog, DialogContent, IconButton, useMediaQuery, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { endpoint } from "../../config";
import axios from "axios"
import { customToast } from "../../lib/toastLib";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function CreditCardDialog({
	open,
	onClose,
}) {
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [name, setName] = useState("");
	const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

	const handleFieldsChange = (e) => {
		setName(e.target.value)
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setSubmitBtnLoading(true)

			const response = await axios.post(`${endpoint}/api/create-credit-card`, {
				category_name: name
			})

			customToast.success(response.data.message)
			onClose(false);
		} catch (error) {
			console.log("Error ::: ", error)
		} finally {
			setSubmitBtnLoading(false)
		}
	}

	return (
		<Dialog
			open={open}
			scroll="paper"
			fullScreen={fullScreen}
			fullWidth
			TransitionComponent={Transition}
			component="form"
			onSubmit={handleSubmit}
			autoComplete="off"
		>
			<DialogContent>
				<Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
					<IconButton onClick={() => onClose(false)}>
						<CloseIcon />
					</IconButton>
					<Grid item xs={12} mb={2}>
						<TextField
							required
							fullWidth
							label={t("Name")}
							value={name}
							onChange={handleFieldsChange}
							name="name"
						/>
					</Grid>

					<Grid item xs={12}>
						<LoadingButton
							color="secondary"
							variant="contained"
							size="large"
							fullWidth
							type="submit"
							disabled={!name}
							loading={submitBtnLoading}
						>
							{t("Submit")}
						</LoadingButton>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}
