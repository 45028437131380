import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  app_title,
  maxAge,
  monthList,
  endpoint,
  ptDomainTracking,
  ptWelcomeBackPageID,
  ptPLCatID,
} from "../config";
import { customToast } from "../lib/toastLib";
import {
  calculateAge,
  dateToyyyymmdd,
  getAppIdFromCookie,
  getProductTypeFromCookie,
  getProspectAppIdFromCookie,
  isValidDate,
  pixelTrackingEvent,
  removeProductIdFromCookie,
  removeProductTypeFromCookie,
  saveAppIdInCookie,
} from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { updateIsEditing } from "../utils/reduxSlice/userSlice";
import axios from "axios";
import maskedInfoApi from "../hooks/GET/maskedInfo";
import Loading from "../components/Loading";

function BusinessWelcomeBack() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appId = getAppIdFromCookie()

  // prospect Id from cookie
  const prospectAppId = getProspectAppIdFromCookie();
  const productType = getProductTypeFromCookie();

  // button loading state start
  const [continueAppLoading, setContinueAppLoading] = useState(false);
  const [panDobLoading, setPanDobLoading] = useState(false);
  // button loading state end

  // enableNewApplication state button
  const [enableNewAppBtn, setEnableNewAppBtn] = useState(false);

  const phoneNumber = useSelector((state) => state.user.phoneNumber) || "";
  // state logic start
  const [state, setState] = useState({
    dob_date: "",
    dob_dateError: "",
    dob_month: "",
    dob_year: "",
    dob_yearError: "",
  });

  useEffect(() => {
    if (productType === 'Business Loans') {
      navigate("/user/business-offers", { replace: true });
    } else if (productType === 'Gold Loans') {
      axios.post(`${endpoint}/api/gold-loans/${appId}`)
        .then(({ data }) => {
          navigate(`/user/${data.redirect}`, { replace: true });
        })
    }
  })


  const handleDOBdateChange = (e) => {
    const value = e.target.value;
    if (isNaN(Number(value))) {
      return;
    }
    setState({
      ...state,
      dob_date: value ? Number(value) : "",
      dob_dateError:
        value && (Number(value) > 31 || Number(value) < 1)
          ? t("Invalid date")
          : "",
    });
  };

  const handleDOBmonthChange = (value) => {
    setState({ ...state, dob_month: value });
  };

  const handleDOByearChange = (e) => {
    const value = e.target.value;
    if (isNaN(Number(value))) {
      return;
    }
    setState({
      ...state,
      dob_year: value ? Number(value) : "",
      dob_yearError:
        value &&
          (value.length !== 4 || Number(value) > new Date().getFullYear())
          ? t("Invalid year")
          : "",
    });
  };
  // state logic end

  // submit button disable state logic start
  const [isSubmitBtnDisbaled, setIsSubmitBtnDisbaled] = useState(true);

  useEffect(() => {
    setIsSubmitBtnDisbaled(
      (!state.dob_date || state.dob_dateError ? true : false) ||
      (!state.dob_month ? true : false) ||
      (!state.dob_year || state.dob_yearError ? true : false)
    );
  }, [state]);
  // submit button disable state logic end

  // handle continue application
  const handleContinueApplication = (e) => {
    e.preventDefault();
    const year = state.dob_year;
    const month = monthList.indexOf(state.dob_month);
    const date = state.dob_date;
    const dob_dateFormat = new Date(year, month, date);

    // validating birth date start
    const isEnteredDateValid = isValidDate(year, month, date);
    if (!isEnteredDateValid || dob_dateFormat > new Date()) {
      customToast.error(t("Please enter a valid birth date"));
      return -1;
    }
    // validating birth date end

    // validating age start
    const age = calculateAge(dob_dateFormat);
    if (age > maxAge) {
      customToast.error(t("Your age doesn't match the required age criteria"));
      return -1;
    }
    // validating age end

    const requestBody = {
      birth_date: dateToyyyymmdd(dob_dateFormat, "-"),
      credit_prospect_id: prospectAppId,
      is_existing: 1,
    };
    setContinueAppLoading(true);
    axios
      .post(`${endpoint}/api/verify-tindob`, requestBody)
      .then((res) => res.data)
      .then((data) => {
        setContinueAppLoading(false);
        saveAppIdInCookie(data.app_id); // saving app_id in cookie
        if (productType === 'Business Loans') {
          navigate("/user/business-offers", { replace: true });
        } else if (productType === 'Gold Loans') {
          axios.post(`${endpoint}/api/gold-loans/${data.app_id}`)
            .then(({ data }) => {
              navigate(`/user/${data.redirect}`, { replace: true });
            })
        }
      })
      .catch((error) => {
        setContinueAppLoading(false);
        setEnableNewAppBtn(true); // enable new application button
        if (error.response) {
          customToast.error(error.response.data?.message);
        } else {
          customToast.error(error.message);
        }
      });
  };

  // handle new application
  const handleNewApplication = (e) => {
    e.preventDefault();
    dispatch(updateIsEditing(1)); // updating user isEditing flag to 1 programatically
    navigate("/user/business-detail", { replace: true });
  };

  // pan and dob logic start
  const [panDob, setPanDob] = useState({ dob: "", fetched: false });

  const handleShowPanDOB = (e) => {
    e.preventDefault();
    setPanDobLoading(true);
    maskedInfoApi(prospectAppId)
      .then((data) => {
        setPanDobLoading(false);
        setPanDob({
          dob: `${t("Your registered DOB")}: ${data.dob}`,
          fetched: true,
        });
      })
      .catch((error) => {
        setPanDobLoading(false);
        console.log(error);
      });
  };
  // pan and dob logic end

  // handle return to product
  const handleReturnToProducts = () => {
    removeProductTypeFromCookie(); // removing product type from cookie
    removeProductIdFromCookie();
    navigate("/products"); // navigating to products
  };

  // Pixel tracking start
  useEffect(() => {
    if (phoneNumber) {
      const trackingPayload = {
        domain: ptDomainTracking,
        txnID: `${phoneNumber}.${ptPLCatID}.${new Date().toLocaleDateString(
          "en-CA"
        )}`,
        conditions: { category_id: String(ptPLCatID), goal_id: String(ptWelcomeBackPageID) },
      };

      pixelTrackingEvent(trackingPayload);
    }
  }, [phoneNumber]);
  // Pixel tracking end

  return (
    <Box sx={{ overflow: "auto", paddingX: 3, paddingY: 5, minHeight: "82vh" }}>
      <Helmet>
        <title>Welcome Back {app_title && `| ${app_title}`}</title>
      </Helmet>
      <Loading />
      {false && <>
        <Button
          size="small"
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          sx={{ marginBottom: 3 }}
          onClick={handleReturnToProducts}
        >
          {t("Return to Products")}
        </Button>
        <Grid
          container
          alignItems="center"
          sx={{
            minWidth: 300,
            justifyContent: { xs: "initial", sm: "center" },
          }}
        >
          <Grid item sm={8} md={6}>
            <Typography variant="h6" color="secondary" fontWeight="bold">
              {t("Welcome Back")}
            </Typography>
            <Typography gutterBottom mb={2}>{t("Welcome Back subtitle of DOB")}</Typography>
            <Typography variant="caption" sx={{ marginTop: 1, marginLeft: 1 }}>
              {t("Birth Date")} *
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: -3 }}>
              <Grid item xs={3}>
                <TextField
                  placeholder="DD"
                  margin="normal"
                  required
                  fullWidth
                  name="dob_date"
                  value={state.dob_date}
                  onChange={handleDOBdateChange}
                  error={state.dob_dateError ? true : false}
                  helperText={state.dob_dateError}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  disableClearable
                  value={state.dob_month}
                  onChange={(e, val) => handleDOBmonthChange(val)}
                  options={["", ...monthList]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      name="dob_month"
                      placeholder="Month"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  placeholder="YYYY"
                  margin="normal"
                  required
                  fullWidth
                  name="dob_year"
                  value={state.dob_year}
                  onChange={handleDOByearChange}
                  error={state.dob_yearError ? true : false}
                  helperText={state.dob_yearError}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
            {panDob.dob && (
              <Typography
                gutterBottom
                variant="body2"
                sx={{ textAlign: "center" }}
                color="#FFBF00"
              >
                {panDob.dob}
              </Typography>
            )}
            <br />
            <LoadingButton
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              onClick={handleContinueApplication}
              disabled={isSubmitBtnDisbaled}
              loading={continueAppLoading}
              sx={{ paddingX: { xs: 0.2, sm: "inherit" } }}
            >
              {t("Continue with existing application")}
            </LoadingButton>
            <Typography
              variant="caption"
              sx={{ display: "block", marginTop: 4 }}
              align="center"
              gutterBottom
            >
              {t("Forgot your DOB?")}
            </Typography>
            <LoadingButton
              color="secondary"
              variant="outlined"
              size="large"
              fullWidth
              onClick={handleShowPanDOB}
              disabled={panDob.fetched}
              loading={panDobLoading}
            >
              {t("Show my DOB")}
            </LoadingButton>
          </Grid>
        </Grid>
      </>}
    </Box>
  );
}

export default BusinessWelcomeBack;
