import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import {
  emailPattern,
  num_length,
  needHelpApi,
  needHelpOrgId,
} from "../config";
import { customToast } from "../lib/toastLib";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function NeedHelpDialog(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // props value
  const { open, handleClose } = props;

  // button loading state start
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  // button loading state end

  // state logic start
  const initialState = {
    contactName: "",
    email: "",
    emailError: "",
    phoneNumber: "",
    phoneNumberError: "",
    company: "",
    description: "",
  };

  const [state, setState] = useState({ ...initialState });

  const handleContactNameChange = (e) => {
    const value = e.target.value;
    setState({ ...state, contactName: value });
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      email: value,
      emailError: value && !value.match(emailPattern) ? t("Email error") : "",
    });
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (isNaN(Number(value)) || value.length > num_length) {
      return;
    }
    setState({
      ...state,
      phoneNumber: value ? Number(value) : "",
      phoneNumberError:
        value && value.length < num_length ? t("Phone Number error") : "",
    });
  };

  const handleCompanyChange = (e) => {
    const value = e.target.value;
    setState({ ...state, company: value });
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setState({ ...state, description: value });
  };
  // state logic end

  // submit button disable state logic start
  const [isSubmitBtnDisbaled, setIsSubmitBtnDisbaled] = useState(true);

  useEffect(() => {
    setIsSubmitBtnDisbaled(
      (!state.contactName ? true : false) ||
      (!state.email || state.emailError ? true : false) ||
      (!state.phoneNumber || state.phoneNumberError ? true : false) ||
      (!state.description ? true : false)
    );
  }, [state]);
  // submit button disable state logic end

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const requestBody = {
      orgid: needHelpOrgId,
      name: state.contactName,
      email: state.email,
      phone: state.phoneNumber,
      company: state.company,
      description: state.description,
    };
    const requestOptions = {
      method: "POST",
      body: new URLSearchParams(requestBody),
    };
    setSubmitBtnLoading(true);
    fetch(needHelpApi, requestOptions)
      .catch(() => null)
      .finally(() => {
        setSubmitBtnLoading(false);
        setState({ ...initialState });
        customToast.success("Your request have been sent successfully");
        handleClose();
      });
  };

  // handle reset
  const handleReset = (e) => {
    e.preventDefault();
    setState({ ...initialState });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      component="form"
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" color="secondary" fontWeight="bold">
              {t("Need help")}?
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose} color="secondary">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom variant="caption" sx={{ display: "block" }}>
          {t("Need help message")}
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          label={t("Contact Name")}
          name="contactName"
          value={state.contactName}
          onChange={handleContactNameChange}
        />
        <TextField
          type="email"
          margin="normal"
          required
          fullWidth
          label={t("Email")}
          name="email"
          value={state.email}
          onChange={handleEmailChange}
          error={state.emailError ? true : false}
          helperText={state.emailError}
        />
        <TextField
          type="tel"
          margin="normal"
          required
          fullWidth
          label={t("Mobile Phone Number")}
          name="phoneNumber"
          value={state.phoneNumber}
          onChange={handlePhoneNumberChange}
          error={state.phoneNumberError ? true : false}
          helperText={state.phoneNumberError}
        />
        <TextField
          margin="normal"
          fullWidth
          label={`${t("Company Name")} (${t("optional")})`}
          name="company"
          value={state.company}
          onChange={handleCompanyChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          multiline
          rows={5}
          label={t("Description")}
          name="description"
          value={state.description}
          onChange={handleDescriptionChange}
        />
      </DialogContent>
      <DialogActions>
        <Grid container sx={{ paddingX: 1.6 }} spacing={1}>
          <Grid item xs={12}>
            <LoadingButton
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              type="submit"
              disabled={isSubmitBtnDisbaled}
              loading={submitBtnLoading}
            >
              {t("Send")}
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              fullWidth
              onClick={handleReset}
              disabled={submitBtnLoading}
            >
              {t("Reset")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
