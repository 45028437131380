import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { removeProductIdFromCookie, removeProductTypeFromCookie, saveProductTypeInCookie } from "../utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function BussinessGSTModal({ open, onClose }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState({
    // have_gst: 1,
    have_registration: 1,
  });
  const navigate = useNavigate();

  const handleGSTNumber = (e) => {
    const value = e.target.value;
    setState({ ...state, have_gst: +value });
  };

  const handleRegistration = (e) => {
    const value = e.target.value;
    setState({ ...state, have_registration: +value });
  };

  const handleBusinessVerify = () => {
    onClose(false, state.have_gst)
    if (state.have_registration === 1) {
      saveProductTypeInCookie("Business Loans");
    } else {
      saveProductTypeInCookie("Personal Loans");
      navigate("/user/user-detail", { replace: true });
    }
    removeProductIdFromCookie();
  };

  const handleOnClose = () => {
    removeProductTypeFromCookie();
    removeProductIdFromCookie();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      scroll="paper"
      fullScreen={fullScreen}
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogContent>
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ mb: 2 }}
        >
          <Typography>Business</Typography>
        </Grid>
        <DialogContentText id="alert-dialog-description">
          {/* <FormControl component="fieldset" required margin="normal">
            <FormLabel component="legend">
              {t("Do you have a GST Number?")}
            </FormLabel>
            <RadioGroup
              row
              name="state"
              value={state.have_gst}
              onChange={handleGSTNumber}
            >
              <FormControlLabel
                value="1"
                control={<Radio checked={state.have_gst === 1} />}
                label="YES"
              />
              <FormControlLabel
                value="0"
                control={<Radio checked={state.have_gst === 0} />}
                label="NO"
              />
            </RadioGroup>
          </FormControl> */}
          {/* {state.have_gst === 0 && ( */}
          <FormControl component="fieldset" required margin="normal">
            <FormLabel component="legend">
              {t("Do you have a business registration certificate?")}
            </FormLabel>
            <RadioGroup
              row
              name="state"
              value={state.have_registration}
              onChange={handleRegistration}
            >
              <FormControlLabel
                value="1"
                control={<Radio checked={state.have_registration === 1} />}
                label="YES"
              />
              <FormControlLabel
                value="0"
                control={<Radio checked={state.have_registration === 0} />}
                label="NO"
              />
            </RadioGroup>
          </FormControl>
          {/* )} */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="secondary"
          variant="contained"
          size="large"
          fullWidth
          type="submit"
          onClick={handleBusinessVerify}
        >
          {t("Continue")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
