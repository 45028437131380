import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Grid,
  List,
  ListItem,
  Drawer,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpIcon from "@mui/icons-material/Help";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { app_title, s3StaticImagePath } from "../config";
import { useTranslation } from "react-i18next";
import logoutApi from "../hooks/POST/logout";
/* import { getUrlSegmentFromCookie } from "../utils";
import { defaultUrlSegment } from "../config"; */
import { useSelector, useDispatch } from "react-redux";
import { updateEditUserDetail } from "../utils/reduxSlice/tempSlice";
import { useNavigate } from "react-router-dom";
import NeedHelpDialog from "./NeedHelpDialog";
import { removeProductIdFromCookie, removeProductTypeFromCookie } from "../utils";

export default function UserHeader() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoURL = useSelector((state) => state.appInfo.logo);

  // whether to show powered by or not start
  const [showPoweredBy /* , setShowPoweredBy */] = useState(false);

  /* useEffect(() => {
    const intervalFunction = setInterval(() => {
      setShowPoweredBy(getUrlSegmentFromCookie() !== defaultUrlSegment);
    }, 1000);
    return () => clearInterval(intervalFunction);
  }, []); */
  // whether to show powered by or not end

  // user detail logic start
  const user = useSelector((state) => state.user);

  const [showUser, setShowUser] = useState(false);
  const pathName = window.location.pathname;

  useEffect(() => {
    // const excludedPaths = ["/user/user-detail", "/user/welcome-back"];
    const excludedPaths = ["/user/user-detail", "/user/welcome-back", "/user/business-detail", "/user/business-welcome-back", "/user/business-offers"];
    const isPresent = excludedPaths.includes(pathName);
    setShowUser(isPresent ? false : true);
  }, [pathName]);
  // user detail logic end

  // whether to show return to profile or not start
  const [showReturnToProfile, setReturnToProfile] = useState(false);

  useEffect(() => {
    const allowedPaths = ["/user/offers"];
    const isPresent = allowedPaths.includes(pathName);
    setReturnToProfile(isPresent);
  }, [pathName]);
  // whether to show return to profile or not end

  // whether to show drawer or not start
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    handleDrawerClose();
    const excludedPaths = ["/user/user-detail"];
    const isPresent = excludedPaths.includes(pathName);
    setShowDrawer(isPresent ? false : true);
  }, [pathName]);
  // whether to show drawer or not end

  //handle return to profile
  const handleReturnToProfile = (e) => {
    e.preventDefault();
    dispatch(updateEditUserDetail(true)); // enabling edit user detail flag in redux store
    navigate("/user/user-detail"); // navigating to user detail
  };

  // handle logout
  const handleLogout = () => {
    logoutApi();
  };

  // need help dialog start
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const handleOpenHelpDialog = () => {
    setShowHelpDialog(true);
  };

  const handleCloseHelpDialog = () => {
    setShowHelpDialog(false);
  };
  // need help dialog end

  // drawer start
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // handle return to product
  const handleReturnToProducts = () => {
    removeProductTypeFromCookie(); // removing product type from cookie
    removeProductIdFromCookie();
    navigate("/products"); // navigating to products
  };

  const drawerBody = (
    <div role="presentation" style={{ width: 250 }}>
      <List>
        <ListItem style={{ justifyContent: "flex-end" }}>
          <IconButton onClick={handleDrawerClose} size="small">
            <CloseIcon />
          </IconButton>
        </ListItem>
        {showUser && (
          <ListItem sx={{ color: "secondary.main" }}>
            <ListItemIcon sx={{ color: "secondary.main" }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={`${user.firstName} ${user.lastName}`} />
          </ListItem>
        )}
        {showReturnToProfile && (
          <ListItem
            button
            sx={{ color: "secondary.main" }}
            onClick={handleReturnToProfile}
          >
            <ListItemIcon sx={{ color: "secondary.main" }}>
              <ArrowBackIosIcon sx={{ paddingLeft: 0.5 }} />
            </ListItemIcon>
            <ListItemText primary={t("Return to Profile")} />
          </ListItem>
        )}
        {showReturnToProfile && process.env.REACT_APP_SUBDOMAIN !== "loans" && (
          <ListItem
            button
            sx={{ color: "secondary.main" }}
            onClick={handleReturnToProducts}
          >
            <ListItemIcon sx={{ color: "secondary.main" }}>
              <ArrowBackIosIcon sx={{ paddingLeft: 0.5 }} />
            </ListItemIcon>
            <ListItemText primary={t("Return to Products")} />
          </ListItem>
        )}
        <ListItem
          button
          sx={{ color: "secondary.main" }}
          onClick={handleOpenHelpDialog}
        >
          <ListItemIcon sx={{ color: "secondary.main" }}>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary={t("Help")} />
        </ListItem>
        <ListItem
          button
          onClick={handleLogout}
          sx={{ color: "error.main", position: "fixed", bottom: 0 }}
        >
          <ListItemIcon sx={{ color: "error.main" }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={t("Logout")} />
        </ListItem>
      </List>
    </div>
  );
  // drawer start

  return (
    <AppBar position="sticky" color="inherit" style={{ overflow: "auto" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Desktop part start */}
          <Box sx={{ mr: 2, display: { xs: "none", md: "flex" } }}>
            {logoURL && <img
              onClick={handleReturnToProducts}
              src={logoURL}
              alt={app_title}
              style={{
                maxHeight: 75,
                maxWidth: 202,
                padding: 5,
                marginRight: 10,
                cursor: 'pointer'
              }}
            />}
            {showPoweredBy && (
              <Grid container>
                <Grid container alignItems="center">
                  <Typography variant="caption">{t("Powered by")}</Typography>
                  <img
                    src={`${s3StaticImagePath}/ongo_logo.png`}
                    alt="powered by"
                    style={{ height: 15, marginLeft: 5 }}
                  />
                </Grid>
                <Grid container>
                  <Typography fontStyle="italic" variant="caption">
                    Dynamic Caption
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            {showUser && (
              <Button
                startIcon={<PersonIcon />}
                sx={{ alignItems: "start" }}
                color="inherit"
                disableRipple
              >
                {user.firstName} {user.lastName}
              </Button>
            )}
            <Button
              startIcon={<HelpIcon />}
              sx={{ alignItems: "start" }}
              onClick={handleOpenHelpDialog}
              color="primary"
            >
              {t("Help")}
            </Button>
            <Button
              startIcon={<LogoutIcon />}
              color="error"
              sx={{ alignItems: "start" }}
              onClick={handleLogout}
            >
              {t("Logout")}
            </Button>
          </Box>
          {/* Desktop part end */}

          {/* Mobile part start */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {showDrawer && (
              <IconButton
                size="large"
                color="inherit"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Box>
          <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
            {drawerBody}
          </Drawer>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {logoURL && <img
              onClick={handleReturnToProducts}
              src={logoURL}
              alt={app_title}
              style={{
                maxHeight: 75,
                maxWidth: 202,
                padding: 5,
                marginRight: 10,
                cursor: 'pointer'
              }}
            />}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            {showPoweredBy && (
              <Grid container>
                <Grid container alignItems="center">
                  <Typography variant="caption">{t("Powered by")}</Typography>
                  <img
                    src={`${s3StaticImagePath}/ongo_logo.png`}
                    alt="powered by"
                    style={{ height: 15, marginLeft: 5 }}
                  />
                </Grid>
                <Grid container>
                  <Typography fontStyle="italic" variant="caption">
                    Dynamic Caption
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>

          {/* Mobile part end */}

          {/* Need help dialog start */}
          <NeedHelpDialog
            open={showHelpDialog}
            handleClose={handleCloseHelpDialog}
          />
          {/* Need help dialog end */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
