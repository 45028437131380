import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";

const maskedInfoApi = async (prospectAppId) => {
  try {
    const apiLink = `${endpoint}/api/getMaskInfo/${prospectAppId}`;
    const response = await axios.get(apiLink);
    const data = response.data;

    const maskedInfoObj = { pan: "", dob: "" };

    if (data) {
      maskedInfoObj.pan = data.data.pan || "";
      maskedInfoObj.dob = data.data.birth_date || "";
    }

    return maskedInfoObj;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

export default maskedInfoApi;
