import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Link,
  Paper,
  TextField,
  MenuItem,
  Box,
} from "@mui/material";
import { corporate_name, endpoint } from "../config";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getProspectAppIdFromCookie } from "../utils";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { updateUserDetail } from "./../utils/reduxSlice/userSlice";
import partners from "../config/partners";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const partner_name = searchParams.get("partner");

  // language list
  const languageList = useSelector((state) => state.languageList);

  // user language value
  const userSlice = useSelector((state) => state.user);
  const userLanguageId = userSlice.languageId;

  // langauge drop down logic start
  const [language, setLanguage] = useState("");
  const partner = partners[process.env.REACT_APP_PARTNER_API_NAME];

  useEffect(() => {
    if (userLanguageId) {
      const defaultLangObj = languageList.find(
        (elem) => elem.id === Number(userLanguageId)
      );
      if (defaultLangObj) {
        i18n.changeLanguage(defaultLangObj.languageName, (err) => {
          if (err) {
            return console.log(err);
          }
          setLanguage(defaultLangObj.id);
        });
      }
    }
  }, [userLanguageId, languageList]);

  const handleLanguageChange = (e) => {
    const value = Number(e.target.value);
    const langObj = languageList.find((elem) => elem.id === value);
    i18n.changeLanguage(langObj.languageName, (err) => {
      if (err) {
        return console.log(err);
      }
      // saving the language preference
      const prospectAppId = getProspectAppIdFromCookie();
      const requestBody = { uid: prospectAppId, language: value };
      axios
        .post(`${endpoint}/api/set-language`, requestBody)
        .then(() => {
          setLanguage(value);
          dispatch(
            updateUserDetail({
              ...userSlice,
              languageId: value,
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  // langauge drop down logic end

  // whether to show language drop down or not start
  const pathName = window.location.pathname;

  const [showLanguageSelect, setShowLanguageSelect] = useState({
    show: false,
    position: null,
  });

  useEffect(() => {
    const doShow = pathName.startsWith(`/user`);
    const topPositionPaths = [`/user/offers`];
    const position = topPositionPaths.includes(pathName) ? "top" : "bottom";
    setShowLanguageSelect((prevState) => ({
      ...prevState,
      show: doShow,
      position: position,
    }));
  }, [pathName]);
  // whether to show language drop down or not end

  // langauge drop down JSX
  const LanguageDropDownComponent = (
    <TextField
      margin="none"
      select
      size="small"
      label="Change your Language"
      name="language"
      value={language}
      onChange={handleLanguageChange}
      style={{ minWidth: 160 }}
    >
      {languageList.map((option, idx) => (
        <MenuItem key={idx} value={option.id}>
          <img
            src={option.image}
            alt=""
            style={{
              height: 25,
              verticalAlign: "bottom",
              marginRight: 10,
            }}
          />
          {t(option.languageName)}
        </MenuItem>
      ))}
    </TextField>
  );

  return (
    <>
      {showLanguageSelect.show && showLanguageSelect.position === "top" && (
        <Grid container sx={{ justifyContent: "center", marginBottom: 2 }}>
          {LanguageDropDownComponent}
        </Grid>
      )}
      <Paper variant="outlined" square sx={{ paddingY: 2 }}>
        <Container maxWidth="md">
          <Grid
            container
            sx={{ justifyContent: { xs: "center", sm: "center" } }}
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography
                style={{ marginRight: 3 }}
                component="span"
                variant="body2"
              >
                ©{new Date().getFullYear()} - {corporate_name} -
              </Typography>
              <Link
                href={
                  partner_name
                    ? `/terms-of-use?partner=${partner_name}`
                    : `/terms-of-use`
                }
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
                color="secondary.main"
                variant="body2"
              >
                Terms of use
              </Link>
              <Typography
                style={{ marginLeft: 3, marginRight: 3 }}
                component="span"
                variant="body2"
              >
                -
              </Typography>
              <Link
                href={
                  partner_name
                    ? `/privacy-policy?partner=${partner_name}`
                    : `/privacy-policy`
                }
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
                color="secondary.main"
                variant="body2"
              >
                Privacy and cookies
              </Link>
              {partner.tancApply && (
                <>
                  <Typography
                    Typography
                    style={{ marginLeft: 3, marginRight: 3 }}
                    component="span"
                    variant="body2"
                  >
                    -
                  </Typography>
                  <Typography
                    style={{ marginRight: 3 }}
                    component="span"
                    variant="body2"
                  >
                    *T&Cs Apply.
                  </Typography>
                </>
              )}
            </Grid>
            {showLanguageSelect.show &&
              showLanguageSelect.position === "bottom" && (
                <Grid item>{LanguageDropDownComponent}</Grid>
              )}
          </Grid>
          <Typography
            style={{
              marginRight: 3,
              textAlign: "center",
              fontSize: 10,
              marginTop: 20,
            }}
            component="p"
            variant="body2"
          >
            {process.env.REACT_APP_PLATFORM_TYPE === "app"
              ? t("SubDescriptionMobile")
              : t("SubDescription")}
          </Typography>
          {/* {process.env.REACT_APP_PARTNER_API_NAME !== "fivekcr" && (
            <Box textAlign={"center"} paddingTop={2} paddingBottom={2}>
              <Typography component="span" variant="body2">
                WhatsApp support no. <b>9818268993</b>
              </Typography>
            </Box>
          )} */}
        </Container>
      </Paper>
    </>
  );
}
