import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// transaltion resource import
import en from "../assets/localization/en-US.json";
import hi from "../assets/localization/hi-IN.json";

// resources/translation object
const resources = {
  English: { translation: en },
  Hindi: { translation: hi },
};

// i18n initialization
i18n.use(initReactI18next).init({
  resources,
  lng: "English",
  fallbackLng: "English",

  interpolation: {
    escapeValue: false,
  },

  react: {
    useSuspense: false,
  },
});

export default i18n;
