import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getProductTypeFromCookie } from "../../utils";

export default function InsuranceLayout() {
  const productType = getProductTypeFromCookie();

  if (productType !== "Other Insurance") {
    // not the correct product type, redirect to products
    return <Navigate to={`/products`} />;
  }

  return <Outlet />;
}
