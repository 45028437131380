import React, { useEffect } from "react";
import { Outlet, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAppIdFromCookie, getProductTypeFromCookie } from "../../utils";
import { useLocation, useRouteMatch } from "react-router-dom";
import { updateEditUserDetail } from "../../utils/reduxSlice/tempSlice";
import { endpoint } from "../../config";
import axios from "axios";

export default function PersonalLoanLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appId = getAppIdFromCookie();
  const productType = getProductTypeFromCookie();
  const user = useSelector((state) => state.user);
  const editUserDetail = useSelector((state) => state.temp.editUserDetail);

  const pathName = window.location.pathname;

  // prettier-ignore
  useEffect(() => {
    if (user.userId) {
      if (
        process.env.REACT_APP_LENDER_TYPE &&
        localStorage.getItem("_lender") &&
        pathName !== "/user/lender-submit" &&
        user.pl_completed === 1
      ) {
        navigate(`/user/lender-submit`, { replace: true });
      } else if (
        process.env.REACT_APP_LENDER_TYPE &&
        localStorage.getItem("_lender") &&
        pathName === "/user/lender-submit"
      ) {
        if (user.pl_completed !== 1) {
          navigate("/user/user-detail", {
            replace: true,
          });
        }
      } else if (
        !appId &&
        pathName !== `/user/welcome-back` &&
        user.pl_completed === 1
      ) {
        // if welcome back process is not done for existing profile, redirect to welcome back
        navigate(`/user/welcome-back`, { replace: true });
      } else if (
        pathName === `/user/user-detail` &&
        user.pl_completed !== 0 &&
        !editUserDetail
      ) {
        // if user detail is filled up and return to profile is not done, redirect to offers or lenders page
        if (
          user.pl_update_required !== 1 &&
          user.pl_se_update_required !== 1 &&
          productType === "Personal Loans"
        ) {
          navigate(`/user/offers`, { replace: true });
        } else if (productType === "Gold Loans") {
          axios.post(`${endpoint}/api/gold-loans/${appId}`)
            .then(({ data }) => {
              navigate(`/user/${data.redirect}`, { replace: true });
            })
        } else {
          dispatch(updateEditUserDetail(true));
        }
      } else if (pathName !== `/user/user-detail` && user.pl_completed !== 1) {
        // if user detail is not filled up, redirect to user detail
        if (
          productType === "Business Loans" &&
          pathName.startsWith("/user/lenders-transaction") &&
          user.bl_completed === 1
        ) {
          return;
        }
        navigate(`/user/user-detail`, { replace: true });
      } else if (
        pathName === `/user/offers` &&
        (user.pl_update_required === 1 || user.pl_se_update_required === 1)
      ) {
        navigate("/user/user-detail", {
          replace: true,
        });
      } else if (
        pathName === `/user/user-detail` &&
        user.pl_completed === 1 &&
        user.pl_update_required === 0 &&
        user.pl_se_update_required === 0 &&
        !editUserDetail
      ) {
        if (productType === "Personal Loans") {
          navigate("/user/offers", {
            replace: true,
          });
        } else if (productType === "Gold Loans") {
          axios.post(`${endpoint}/api/gold-loans/${appId}`)
            .then(({ data }) => {
              navigate(`/user/${data.redirect}`, { replace: true });
            })
        }
      }
    }

    // Check if the current location matches the specified route
    // const isMatchingRoute = match && location.pathname === match.url;
    // if()

  }, [user, pathName]);

  if (
    productType !== "Personal Loans" &&
    productType !== "Gold Loans" &&
    productType === "Business Loans" &&
    !pathName.startsWith("/user/lenders-transaction")
  ) {
    return <Navigate to={`/products`} />;
  }

  return <Outlet />;
}
