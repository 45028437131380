import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getPartnerJWTTokenFromCookie } from "../../utils";
import { Helmet } from "react-helmet-async";
import PartnerContainer from "./PartnerContainer";

export default function PartnerLayout() {
  const authToken = getPartnerJWTTokenFromCookie();

  if (!authToken) {
    // if verification process not completed, redirect to login
    return <Navigate to="/partner/login" />;
  }

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <style>
          {`
					body {
						font-family: 'Roboto', sans-serif !important;
					}
					`}
        </style>
      </Helmet>
      <PartnerContainer>
        <Outlet />
      </PartnerContainer>
    </>
  );
}
