import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAppIdFromCookie, getProductTypeFromCookie } from "../../utils";
import BussinessGSTModal from "../BussinessGSTModal";

export default function BusinessLoanLayout() {
  const navigate = useNavigate();

  const appId = getAppIdFromCookie();
  const productType = getProductTypeFromCookie();
  const user = useSelector((state) => state.user);
  const editUserDetail = useSelector((state) => state.temp.editUserDetail);

  const pathName = window.location.pathname;

  // prettier-ignore
  useEffect(() => {
    if (user.userId) {
      // user data is fetched
      if (!appId && pathName !== `/user/business-welcome-back` && user.bl_completed === 1) {
        // if welcome back process is not done for existing profile, redirect to welcome back
        navigate(`/user/business-welcome-back`, { replace: true });
      } else if (pathName === `/user/business-detail` && user.bl_completed !== 0 && !editUserDetail) {
        // if user detail is filled up and return to profile is not done, redirect to offers or lenders page
        navigate(`/user/business-offers`, { replace: true });
      } else if (pathName !== `/user/business-detail` && user.bl_completed !== 1) {
        // if user detail is not filled up, redirect to user detail
        navigate(`/user/business-detail`, { replace: true });
      }
    }
  }, [user, pathName]);

  if (productType !== "Business Loans") {
    // not the correct product type, redirect to products
    return <Navigate to={`/products`} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}
