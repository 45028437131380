import { Paper, Typography, Box, Button, IconButton, Switch, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next'
import { app_title, endpoint } from '../../config';
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react"
import CreditCardDialog from '../../components/admin/CreditCardDialog';
import DeleteCardDialog from '../../components/admin/DeleteCardDialog';
import CreditCardUpdateDialog from '../../components/admin/CreditCardUpdateDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';

import axios from "axios"
import { customToast } from '../../lib/toastLib';

export default function CreditCards() {
	const { t } = useTranslation();
	const [openCreateDialog, setOpenCreateDialog] = useState(false);
	const [creditCardsList, setCreditCardsList] = useState([]);
	const [deleteIndex, setDeleteIndex] = useState(-1);
	const [deleteLoading, setDeleteLoading] = useState(false)

	useEffect(() => {
		fetchRecords()
	}, [])

	const fetchRecords = async () => {
		try {
			const { data } = await axios.get(`${endpoint}/api/get-credit-cards-data`);
			setCreditCardsList(data.sort(function (a, b) {
				return a.sort_order - b.sort_order;
			}));
		} catch (error) {
			console.error("Error in /api/get-credit-cards-data", error)
		}
	}

	const handleCreateDialogClose = (isClose) => {
		setOpenCreateDialog(isClose);
		fetchRecords();
	}

	const deleteCreditCard = async () => {
		try {
			setDeleteLoading(true);

			const { data } = await axios.post(`${endpoint}/api/safe-delete-credit-card`, {
				category_id: deleteIndex
			});

			customToast.success(data.message)
			fetchRecords();
			setDeleteIndex(-1);
		} catch (error) {
			console.error("Error in soft-delete API:: ", error)
			customToast.error(error)
		} finally {
			setDeleteLoading(false);
		}
	}

	const [editName, setEditName] = useState(null);
	const [editRecord, setEditRecord] = useState({});
	const handleEdit = (name, record) => {
		setEditName(name)
		setEditRecord(record)
	}

	const handleSwitchChange = async (e, record) => {
		try {
			const payload = {
				category_id: record.category_id,
				property: "status",
				value: e.target.checked ? 1 : 0
			}

			await axios.post(`${endpoint}/api/update-credit-card-data`, payload)
			fetchRecords()
		} catch (error) {
			console.error("Error::: ", error)
			customToast.error(error)
		}
	}

	return (
		<>
			<Helmet>
				<title>Admin - Partners {app_title && `| ${app_title}`}</title>
			</Helmet>
			<Paper variant="outlined" sx={{ padding: 2 }}>
				<Box sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}>
					<Typography
						variant="h6"
						fontWeight="bold"
						color="secondary.main"
						gutterBottom
					>
						{t("Credit Cards")}
					</Typography>

					<Button
						variant="contained"
						startIcon={<AddIcon />}
						onClick={() => setOpenCreateDialog(true)}
					>
						{t("Create")}
					</Button>
				</Box>

				<TableContainer component={Paper} variant="outlined" sx={{ mt: 3 }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell align='center' sx={{
									maxWidth: '200px'
								}}>{t("Sorting Order")}</TableCell>
								<TableCell align='center' sx={{
									maxWidth: '200px'
								}}>{t("Thumbnail")}</TableCell>
								<TableCell align='center' sx={{
									maxWidth: '200px'
								}}>{t("Name")}</TableCell>
								<TableCell align='center' sx={{
									maxWidth: '200px'
								}}>{t("Key Features")}</TableCell>
								<TableCell align='center' sx={{
									maxWidth: '200px'
								}}>{t("Annual Fees")}</TableCell>
								<TableCell align='center' sx={{
									maxWidth: '200px'
								}}>{t("UTM Link")}</TableCell>
								<TableCell align='center' sx={{
									maxWidth: '200px'
								}}>{t("Features")}</TableCell>
								<TableCell align='center' sx={{
									maxWidth: '200px'
								}}>{t("Status")}</TableCell>
								<TableCell align='center' sx={{
									maxWidth: '200px'
								}}>{t("Actions")}</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{creditCardsList.map((list, idx) => (
								<TableRow
									key={idx}
									sx={{
										"&:last-child td, &:last-child th": { border: 0 },
									}}
								>
									<TableCell align='center' sx={{
										position: 'relative',
										maxWidth: '200px',
										'&:hover .hover-box': {
											display: 'flex',
										},
									}}>
										{list.sort_order}
										<EditContent onClick={() => handleEdit('sort_order', list)} />
									</TableCell>
									<TableCell align='center' sx={{
										position: 'relative',
										maxWidth: '200px',
										'&:hover .hover-box': {
											display: 'flex',
										},
									}}>
										{list.category_img_thumb ? <img
											src={list.category_img_thumb}
											style={{
												width: '100px'
											}}
										/> :
											(<Typography fontSize={12}>No Thumbnail Image found</Typography>)
										}
										<EditContent onClick={() => handleEdit('category_img_thumb', list)} />
									</TableCell>
									<TableCell align='center'>{list.category_name}</TableCell>
									<TableCell align='center' sx={{
										position: 'relative',
										maxWidth: '200px',
										'&:hover .hover-box': {
											display: 'flex',
										},
									}}>
										{list.key_feature ?? '-'}
										<EditContent onClick={() => handleEdit('key_feature', list)} />
									</TableCell>
									<TableCell align='center' sx={{
										position: 'relative',
										maxWidth: '200px',
										'&:hover .hover-box': {
											display: 'flex',
										},
									}}>
										{list.annual_fee ?? '-'}
										<EditContent onClick={() => handleEdit('annual_fee', list)} />
									</TableCell>
									<TableCell align='center' sx={{
										position: 'relative',
										maxWidth: '200px',
									}}>
										<Button onClick={() => handleEdit('utm_link', list)}>
											View/Update UTM Link
										</Button>
									</TableCell>
									<TableCell align='center' sx={{
										position: 'relative',
										maxWidth: '200px',
									}}>
										<Button onClick={() => handleEdit('marketing_bullets', list)}>
											View/Update Feature
										</Button>
									</TableCell>
									<TableCell
										sx={{
											maxWidth: '200px'
										}}
									>
										<Switch
											checked={list.status == 1}
											onChange={(e) =>
												handleSwitchChange(e, list)
											}
											size="small"
										/>
									</TableCell>
									<TableCell align='center'>
										<IconButton
											color="error"
											variant="contained"
											size="small"
											onClick={() => setDeleteIndex(list.category_id)}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>

				<CreditCardDialog
					open={openCreateDialog}
					onClose={handleCreateDialogClose}
				/>

				<DeleteCardDialog
					open={deleteIndex !== -1}
					onDelete={deleteCreditCard}
					onClose={() => setDeleteIndex(-1)}
					loading={deleteLoading}
				/>

				<CreditCardUpdateDialog
					open={!!editName}
					name={editName}
					record={editRecord}
					onClose={() => {
						setEditRecord({})
						setEditName(null)
					}}
					onSave={() => {
						fetchRecords()
						setEditRecord({})
						setEditName(null)
					}}
					isPartner={false}
				/>
			</Paper>
		</>
	)
}

export const EditContent = ({ onClick }) => {
	return <Box
		className='hover-box'
		sx={{
			position: 'absolute',
			top: 0,
			left: 0,
			padding: 1,
			width: '100%',
			height: '100%',
			display: 'none',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'rgba(0, 0, 0, 0.05)'
		}}>
		<IconButton
			color="primary"
			size="small"
			sx={{
				background: 'white',
				'&:hover': {
					background: 'white'
				}
			}}
			onClick={onClick}
		>
			<CreateIcon />
		</IconButton>
	</Box>
}
