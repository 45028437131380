import React from "react";
import UserHeader from "./UserHeader";
import Footer from "./Footer";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  getJWTTokenFromCookie,
  getProductTypeFromCookie,
  getProspectAppIdFromCookie,
} from "../utils";
import { Container } from "@mui/material";

export default function UserLayout() {
  const prospectAppId = getProspectAppIdFromCookie();
  const authToken = getJWTTokenFromCookie();
  const productType = getProductTypeFromCookie();
  const router = useLocation()

  if (!prospectAppId) {
    // if apply loan process not completed, redirect to home
    return <Navigate to="/" />;
  } else if (!authToken) {
    // if verification process not completed, redirect to verification
    return <Navigate to="/verification" />;
  } else if (!productType) {
    // if product selection process not completed, redirect to products
    return <Navigate to="/products" />;
  }

  const isLenderSubmit = router.pathname === "/user/lender-submit"

  return (
    <>
      {!isLenderSubmit && <UserHeader />}
      <Container maxWidth="xl">
        <Outlet />
      </Container>
      {!isLenderSubmit && <Footer />}
    </>
  );
}
