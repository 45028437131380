import axios from "axios";
import logoutApi from "../hooks/POST/logout";
import {
  isAdminRoute,
  getAdminJWTTokenFromCookie,
  getJWTTokenFromCookie,
  getPartnerJWTTokenFromCookie,
  isPartnerRoute,
} from "../utils";

axios.interceptors.request.use(
  (config) => {
    let token = getJWTTokenFromCookie();
    if (isAdminRoute()) token = getAdminJWTTokenFromCookie();
    if (isPartnerRoute()) token = getPartnerJWTTokenFromCookie();
    if (token) config.headers.authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // logoutApi();
    }
    return Promise.reject(error);
  }
);

export default axios;
