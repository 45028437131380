import { configureStore } from "@reduxjs/toolkit";

//slice imports
import userReducer from "../utils/reduxSlice/userSlice";
import smartListReducer from "../utils/reduxSlice/smartListSlice";
import tempReducer from "../utils/reduxSlice/tempSlice";
import languageListReducer from "../utils/reduxSlice/languageListSlice";
import appInfoReducer from "../utils/reduxSlice/appInfoSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    smartList: smartListReducer,
    temp: tempReducer,
    languageList: languageListReducer,
    appInfo: appInfoReducer
  },
  devTools: process.env.NODE_ENV === "development", // to disable devtools access other than development
});
