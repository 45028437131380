import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import RuleIcon from "@mui/icons-material/Rule";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BarChartIcon from "@mui/icons-material/BarChart";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MessageIcon from '@mui/icons-material/Message';
import RoundaboutRightIcon from "@mui/icons-material/RoundaboutRight";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { app_title, s3StaticImagePath } from "../../config";
import { clearAdminCookieData, getRoleIdFromCookie } from "../../utils";
import { hasAccessPermission } from "../../utils/routerHelpers";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: 0,
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  "&::-webkit-scrollbar": {
    width: 0,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function AdminContainer({ children }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const roleId = getRoleIdFromCookie();

  // drawer open state logic start
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // drawer open state logic end

  const logoURL = useSelector((state) => state.appInfo.logo);

  const [drawerNestedRow, setDrawerNestedRow] = useState({
    report: false,
  });

  // drawer list logic start
  const handleNavigation = (obj) => {
    if (!obj.isChildren) {
      const keys = {};
      for (const key in drawerNestedRow) {
        keys[key] = false;
      }
      setDrawerNestedRow(keys);
    }
    navigate(obj.navLink);
  };

  const handleLogout = () => {
    clearAdminCookieData(); // clearing all cookie data of admin
    window.location = "/admin/login";
  };

  const handleDrawerNestedRow = (key) => {
    setOpen(true);
    setDrawerNestedRow({
      ...drawerNestedRow,
      [key]: !drawerNestedRow[key],
    });
  };

  const isLinkSelected = (obj, location) => {
    if (obj.isChildren) {
      setOpen(true);
      setDrawerNestedRow({
        ...drawerNestedRow,
        [obj.parentKey]: true,
      });
    }

    return location.pathname.startsWith(obj.navLink);
  };

  const drawerList = [
    {
      label: t("Dashboard"),
      navLink: "/admin/dashboard",
      icon: <DashboardIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/dashboard", roleId),
      action: handleNavigation,
    },
    {
      label: t("MIS Upload"),
      navLink: "/admin/mis-upload",
      icon: <UploadFileIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/mis-upload", roleId),
      action: handleNavigation,
    },
    {
      label: t("Vendors On/Off"),
      navLink: "/admin/vendors-on-off",
      icon: <BusinessCenterIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/vendors-on-off", roleId),
      action: handleNavigation,
    },
    {
      label: t("Lenders"),
      navLink: "/admin/lenders",
      icon: <BusinessCenterIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/lenders", roleId),
      action: handleNavigation,
    },
    {
      label: t("Customer Eligibility"),
      navLink: "/admin/customer-eligibility",
      icon: <RuleIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/customer-eligibility", roleId),
      action: handleNavigation,
    },
    {
      label: t("CSR Portal"),
      navLink: "/admin/csr-portal",
      icon: <TextSnippetIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/csr-portal", roleId),
      action: handleNavigation,
    },
    {
      label: t("Internal Reports"),
      navLink: "/admin/internal-report",
      icon: <BarChartIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/internal-report", roleId),
      action: handleNavigation,
    },
    {
      label: t("Customer Info Deletion"),
      navLink: "/admin/customer-info-deletion",
      icon: <PersonRemoveIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/customer-info-deletion", roleId),
      action: handleNavigation,
    },
    {
      label: t("Customers Journey"),
      navLink: "/admin/customers-journey",
      icon: <RoundaboutRightIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/customers-journey", roleId),
      action: handleNavigation,
    },
    {
      label: t("User Management"),
      navLink: "/admin/user-management",
      icon: <ManageAccountsIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/user-management", roleId),
      action: handleNavigation,
    },
    {
      label: t("Commission Upload File"),
      navLink: "/admin/commission-upload-file",
      icon: <UploadFileIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/commission-upload-file", roleId),
      action: handleNavigation,
    },
    {
      label: t("Application Status File"),
      navLink: "/admin/application-status-file",
      icon: <UploadFileIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/application-status-file", roleId),
      action: handleNavigation,
    },
    {
      label: t("Merchants"),
      navLink: "/admin/merchant-management",
      icon: <ManageAccountsIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/merchant-management", roleId),
      action: handleNavigation,
    },
    {
      label: t("Tracking Links"),
      navLink: "/admin/tracking-links",
      icon: <TrendingUpIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/tracking-links", roleId),
      action: handleNavigation,
    },
    {
      label: t("Credit Cards"),
      navLink: "/admin/credit-cards",
      icon: <CreditCardIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/credit-cards", roleId),
      action: handleNavigation,
    },
    {
      label: t("Partner Config"),
      navLink: "/admin/partners",
      icon: <PeopleAltIcon />,
      divider: false,
      visible: hasAccessPermission("/admin/partners", roleId),
      action: handleNavigation,
    },
    {
      label: t("Messaging"),
      navLink: "/admin/messaging",
      icon: <MessageIcon />,
      divider: true,
      visible: hasAccessPermission("/admin/messaging", roleId),
      action: handleNavigation,
    },
    {
      label: t("Logout"),
      navLink: null,
      icon: <LogoutIcon color="error" />,
      divider: false,
      visible: true,
      action: handleLogout,
    },
  ];

  const renderListItemButton = (obj, open, location, isChildren) => {
    return (
      <ListItemButton
        key={obj.label}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          borderRadius: 1,
          whiteSpace: open ? "normal" : "inherit",
        }}
        selected={isLinkSelected(obj, location)}
        onClick={() =>
          obj.children?.length > 0
            ? handleDrawerNestedRow(obj.key)
            : obj.action(obj)
        }
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
            pl: open && isChildren ? 2 : 0,
          }}
        >
          {obj.icon}
        </ListItemIcon>
        <ListItemText primary={obj.label} sx={{ opacity: open ? 1 : 0 }} />
        {open &&
          obj.children &&
          obj.children.length > 0 &&
          (drawerNestedRow[obj.key] ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
    );
  };

  const drawerListItemButton = (obj, open, location, isChildren) => {
    return !open ? (
      <Tooltip key={obj.label} title={obj.label} placement="right">
        {renderListItemButton(obj, open, location, isChildren)}
      </Tooltip>
    ) : (
      renderListItemButton(obj, open, location, isChildren)
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open} color="inherit">
        <Toolbar sx={{ marginLeft: { xs: "5px", sm: "0px", md: "0px" } }}>
          <IconButton
            color="inherit"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ marginRight: 5, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          {logoURL && <img
            src={logoURL}
            alt={app_title}
            style={{ maxHeight: 75, maxWidth: 202, padding: 5 }}
          />}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {drawerList.map((obj, idx) => {
            return (
              obj.visible && (
                <ListItem
                  key={idx}
                  dense
                  sx={{ display: "block", px: 1 }}
                  divider={obj.divider}
                >
                  <>
                    {drawerListItemButton(obj, open, location, false)}
                    {obj.children?.length > 0 && (
                      <Collapse
                        in={drawerNestedRow[obj.key]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List>
                          {obj.children.map((child) =>
                            drawerListItemButton(child, open, location, true)
                          )}
                        </List>
                      </Collapse>
                    )}
                  </>
                </ListItem>
              )
            );
          })}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: "auto" }}>
        <DrawerHeader />
        <Box sx={{ minWidth: 300 }}>{children}</Box>
      </Box>
    </Box>
  );
}
