import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  {
    id: 1,
    languageName: "English",
    image: "",
    isDefault: 1,
    country_key: "IN",
    country_name: "India",
    phone_code: 91,
  },
];

export const languageListSlice = createSlice({
  name: "languageList",
  initialState,
  reducers: {
    updateLanguageList: (state, action) => {
      while (state.length > 0) {
        state.pop();
      }
      state.push(...action.payload);
    },
  },
});

// actions export
export const { updateLanguageList } = languageListSlice.actions;

// initial state export
export { initialState };

export default languageListSlice.reducer;
