import { forwardRef } from "react";
import { Slide, Dialog, DialogContent, useMediaQuery, Typography, Box, Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function CreditCardDialog({
	open,
	onClose,
	onDelete,
	loading
}) {
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Dialog
			open={open}
			fullScreen={fullScreen}
			fullWidth
			TransitionComponent={Transition}
		>
			<DialogContent>
				<Typography align='center' variant="h6" color="secondary.main" mb={2}>Are you sure?</Typography>
				<Box sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					gap: 2,
				}}>
					<Button
						color="primary"
						variant="outlined"
						size="small"
						onClick={onClose}
					>
						Cancel
					</Button>
					<LoadingButton
						loading={loading}
						color="error"
						variant="contained"
						size="small"
						onClick={onDelete}
					>
						Delete
					</LoadingButton>
				</Box>
			</DialogContent>
		</Dialog>
	)
}

const style = (theme) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	borderRadius: '12px',
	padding: '16px 32px 24px 32px',
	backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
	boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
});