import { createSlice } from "@reduxjs/toolkit";
import partners, { partnersList } from "../../config/partners";

const partner_name = process.env.REACT_APP_PARTNER_API_NAME;
const partner = partnersList.includes(partner_name) ? partner_name : "l";

const initialState = {
  partner: partner,
  logo: partners[partner].logo,
  googleFontsAPI: partners[partner].googleFontsAPI,
  fontFamily: partners[partner].fontFamily,
};

export const appInfoSlice = createSlice({
  name: "appInfo",
  initialState,
  reducers: {
    updateLogoLink: (state, action) => {
      state.partner = action.payload.partner;
      state.logo = action.payload.logo;
      state.googleFontsAPI = action.payload.googleFontsAPI;
      state.fontFamily = action.payload.fontFamily;
    },
  },
});

// actions export
export const { updateLogoLink } = appInfoSlice.actions;

// initial state export
export { initialState };

export default appInfoSlice.reducer;
