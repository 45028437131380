import React from "react";
import { Helmet } from "react-helmet-async";
import { Outlet, Navigate } from "react-router-dom";
import { getPartnerJWTTokenFromCookie } from "../../utils";

export default function GenericPartnerLayout() {
  const authToken = getPartnerJWTTokenFromCookie();

  if (authToken) {
    // if verification process is completed, redirect to dashboard
    return <Navigate to="/partner/dashboard" />;
  }

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <style>
          {`
					body {
						font-family: 'Roboto', sans-serif !important;
					}
					`}
        </style>
      </Helmet>
      <Outlet />
    </>
  );
}
