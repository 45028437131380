import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";
import { clearCookieData } from "../../utils";

const logoutApi = async () => {
  try {
    const apiLink = `${endpoint}/api/logout`;
    await axios.post(apiLink);
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
  } finally {
    clearCookieData(); // clearing up all the session data stored in cookie
    window.location.reload();
  }
};

export default logoutApi;
