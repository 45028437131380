import axios from "axios";
import { endpoint, countryCode } from "../../config";
import { customToast } from "../../lib/toastLib";
import { initialState } from "../../utils/reduxSlice/languageListSlice";

const languageListApi = async () => {
  const dataArr = [];

  try {
    const apiLink = `${endpoint}/api/getLanguages`;
    const response = await axios.post(apiLink, { countryCode: countryCode });
    const data = response.data;

    if (data && data.data) {
      data.data.forEach((elem) => {
        dataArr.push({
          id: elem.id,
          languageName: elem.languageName,
          image: elem.image,
          isDefault: elem.isDefault,
          country_key: elem.country_key,
          country_name: elem.country_name,
          phone_code: elem.phone_code,
        });
      });
    }

    return dataArr.length !== 0 ? dataArr : [...initialState];
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

export default languageListApi;
