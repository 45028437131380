import React, { useEffect } from "react";
import AdminContainer from "./AdminContainer";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { getAdminJWTTokenFromCookie, getRoleIdFromCookie } from "../../utils";
import { Helmet } from "react-helmet-async";
import { hasAccessPermission } from "../../utils/routerHelpers";

export default function AdminLayout() {
  const authToken = getAdminJWTTokenFromCookie();
  const location = useLocation();

  if (!authToken) {
    // if verification process not completed, redirect to login
    return <Navigate to="/admin/login" />;
  }
  
  const roleId = getRoleIdFromCookie();
  if (!hasAccessPermission(location.pathname, roleId)) {
    return <Navigate to="/admin/dashboard" />;
  }
    return (
      <>
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
          <style>
            {`
					body {
						font-family: 'Roboto', sans-serif !important;
					}
					`}
          </style>
        </Helmet>
        <AdminContainer>
          <Outlet />
        </AdminContainer>
      </>
    );
}
