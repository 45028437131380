import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

function SuspenseLoader() {
  const style = {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgColor: "#ffffff",
  };

  return (
    <Box sx={style}>
      <CircularProgress />
    </Box>
  );
}

export default SuspenseLoader;
