import React, { useEffect } from "react";
import GenericHeader from "./GenericHeader";
import Footer from "./Footer";
import { Outlet, useNavigate } from "react-router-dom";
import {
  getAppIdFromCookie,
  getJWTTokenFromCookie,
  getProductTypeFromCookie,
  getProspectAppIdFromCookie,
  isPrimaryDomain,
} from "../utils";
import { Container } from "@mui/material";

export default function GenericLayout() {
  const navigate = useNavigate();

  const prospectAppId = getProspectAppIdFromCookie();
  const authToken = getJWTTokenFromCookie();
  const productType = getProductTypeFromCookie();
  const appId = getAppIdFromCookie();

  const pathName = window.location.pathname;
  const excludedPaths = [`/privacy-policy`, `/terms-of-use`];

  // prettier-ignore
  useEffect(() => {
    if (!excludedPaths.includes(pathName)) {
      if (!prospectAppId && (pathName === `/verification` || pathName === `/credit-cards`)) {
        // if apply loan is not done but jumped to verification, redirect to home
        navigate(isPrimaryDomain ? '/login' : `/`);
      }
      else if (!isPrimaryDomain && (!prospectAppId || !authToken) && pathName === `/products`) {
        // if apply loan or verification is not done but jumped to product type selection, redirect to home
        navigate(`/`);
      }
      else if (prospectAppId && authToken && !productType && pathName !== (isPrimaryDomain ? '/' : `/products`)) {
        // if apply loan and verification is done but product type selection is pending, redirect to products
        navigate(isPrimaryDomain ? '/' : `/products`);
      }
      else if (prospectAppId && authToken && productType === "Personal Loans" && appId) {
        // if apply loan, verification and welcome back/user detail is done, redirect to offers
        navigate(`/user/offers`);
      } else if (prospectAppId && authToken && productType === "Personal Loans" && !appId) {
        // if apply loan and verification done but welcomeback/user detail is pending, redirect to welcomeback
        navigate(`/user/welcome-back`);
      }
    }
  }, [pathName]);

  return (
    <>
      <GenericHeader />
      <Container maxWidth="xl">
        <Outlet />
      </Container>
      <Footer />
    </>
  );
}
